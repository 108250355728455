import './risk-modal.styles.scss';
import React, {
	useState,
	FC,
	Dispatch,
	SetStateAction,
	FormEvent,
	useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../redux/General/general.actions';
import { setStageRisks } from '../../../redux/Profile/profile.actions';
import { RootState } from '../../../types/root-state';
import {
	IRiskItem,
	IStageDataItem,
	IRiskTypeData,
	IRiskPayload,
} from '../../../types/interfaces/stage.interface';
import { IStaffMember } from '../../../types/interfaces/staff.interface';
import { IDropdownStandard } from '../../../types/interface';

import * as apiStage from '../../../api/api.stage';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import OrkaButton from '../../general/orka-button/orka-button.component';

import checkboxBlue from '../../../assets/icons/checkbox-blue.svg';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';

type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	stage: IStageDataItem;
	risk?: IRiskItem;
};

const RiskModal: FC<Props> = ({ setShowModal, stage, risk = null }) => {
	const dispatch = useDispatch();

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const globalStaff = useSelector((state: RootState) => state.auth.staff);

	const globalCompanies = useSelector(
		(state: RootState) => state.auth.company,
	);

	const [isTypeRisk, setIsTypeRisk] = useState(
		risk && risk.risk.description === 'risk',
	);

	const [controllers, setControllers] = useState<IDropdownStandard[]>([]);
	const [defaultController, setDefaultController] =
		useState<IDropdownStandard>();
	const [selectedController, setSelectedController] = useState<string>('');

	const [companies, setCompanies] = useState<IDropdownStandard[]>([]);
	const [defaultCompany, setDefaultCompany] = useState<IDropdownStandard>();
	const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');

	const [isDisabled, setIsDisabled] = useState(false);

	const [riskTypeData, setRiskTypeData] = useState<IRiskTypeData[]>([
		{
			riskType: 'Failed Screening',
			dbVal: 'failedScreening',
			selected: !!(risk && risk.risk.description === 'failedScreening'),
		},
		{
			riskType: 'Flag',
			dbVal: 'flag',
			selected: !!(risk && risk.risk.description === 'flag'),
		},
		{
			riskType: 'Risk',
			dbVal: 'risk',
			selected: !!(risk && risk.risk.description === 'risk'),
		},
	]);
	const [riskNotes, setRiskNotes] = useState('');
	const [assignedComments, setAssignedComments] = useState<string>('');

	useEffect(() => {
		const text = '(assignedComments >)';

		if (risk) {
			const [raisedRisk, assigned] = risk.riskNotes.split(text);
			setAssignedComments(assigned ? assigned : '');
			setRiskNotes(raisedRisk ? raisedRisk : '');
		} else {
			setRiskNotes('');
			setAssignedComments('');
		}
	}, []);

	useEffect(() => {
		if (risk && risk.assignedTo) {
			const localSelectedController = controllers.filter((controller) => {
				return controller.dbValue === risk.assignedTo;
			});
			if (localSelectedController.length > 0 && isTypeRisk) {
				setSelectedController(localSelectedController[0].dbValue);
				setDefaultController(localSelectedController[0]);
			}
		}
	}, [controllers.length]);

	useEffect(() => {
		if (!applicant.clientIds) return;

		const filteredCompanies = globalCompanies
			.filter((company) =>
				applicant.clientIds.includes(String(company.id)),
			)
			.map((company, index) => ({
				id: index + 1,
				value: company.companyName,
				dbValue: company.companyName,
				class: 'dropdown-grey',
			}));

		const defaultItem = {
			id: 0,
			value: 'Select',
			dbValue: 'select',
			disabled: true,
			class: 'dropdown-grey',
		};

		setControllers([defaultItem]);
		setCompanies([defaultItem, ...filteredCompanies]);
	}, [applicant.clientIds, globalCompanies]);

	useEffect(() => {
		if (controllers.length > 0 && risk && risk.assignedTo) {
			const defaultController = controllers.filter((controller) => {
				return controller.email === risk.assignedTo;
			});
			setDefaultController(defaultController[0]);
		} else {
			setDefaultController(controllers[0]);
		}
	}, [controllers.length]);

	useEffect(() => {
		if (!isTypeRisk) {
			setSelectedController('');
			setSelectedCompanyName('');
			setDefaultController(controllers[0]);
		}
	}, [isTypeRisk]);

	useEffect(() => {
		let disabled = false;
		if (!riskNotes) {
			disabled = true;
		}
		if (isTypeRisk && !selectedController) {
			disabled = true;
		}
		setIsDisabled(disabled);
	}, [selectedController, isTypeRisk, riskNotes]);

	useEffect(() => {
		const defaultItem = {
			id: 0,
			value: 'Select',
			dbValue: 'select',
			disabled: true,
			class: 'dropdown-grey',
		};

		if (companies.length > 0 && globalStaff.length > 0) {
			const filteredStaff = globalStaff
				.filter(
					(staff) =>
						staff.company.companyName === selectedCompanyName &&
						(staff.role === 'Controller' ||
							staff.role === 'Manager'),
				)
				.reduce((acc: any, curr: IStaffMember, index) => {
					const newController = {
						id: index + 1,
						value: curr.name + ' ' + curr.surname,
						dbValue: curr.uuid,
						email: curr.email,
						class: 'dropdown-grey',
					};
					return [...acc, newController];
				}, []);
			setControllers([defaultItem, ...filteredStaff]);
		}
	}, [companies.length, globalStaff.length, selectedCompanyName]);

	const handleTextareaChange = (e: FormEvent<HTMLTextAreaElement>) => {
		setRiskNotes(e.currentTarget.value);
	};

	const handleSelectRisk = (val: string) => {
		const newData = riskTypeData.map((risk) => {
			risk.selected = risk.dbVal === val ? !risk.selected : false;
			return risk;
		});
		newData.forEach((risk) => {
			if (risk.riskType === 'Risk' && risk.selected) {
				setIsTypeRisk(true);
			} else {
				setIsTypeRisk(false);
				setDefaultController(controllers[0]);
				setDefaultCompany(companies[0]);
			}
		});
		setRiskTypeData(newData);
	};

	const handleSave = () => {
		const type: IRiskTypeData | undefined = riskTypeData.find(
			(el) => el.selected,
		);
		const assignedRiskNotes = assignedComments
			? '(assignedComments >)' + assignedComments
			: '';

		if (type) {
			const payload = {
				riskType: type.dbVal,
				riskNotes: riskNotes + assignedRiskNotes,
				stageUuid: stage.uuid,
				assignedToUuid: selectedController ? selectedController : null,
			};
			return risk ? updateRisk(payload) : postRisk(payload);
		}
	};

	const getDropdownVal = (val: IDropdownStandard) => {
		setSelectedController(val.dbValue);
		setDefaultController(val);
	};

	const getCompanyDropdownVal = (val: IDropdownStandard) => {
		setSelectedCompanyName(val.dbValue);
		setDefaultCompany(val);
	};

	const postRisk = (payload: IRiskPayload) => {
		apiStage
			.postRisk(applicant.workerUuid, payload)
			.then((res) => {
				if (res.status !== 404) {
					dispatch(setStageRisks(res.data));
					dispatch(
						setAlert({
							type: 'success',
							message: 'Successfully added risk',
							isVisible: true,
						}),
					);
				} else {
					dispatch(
						setAlert({
							type: 'Error',
							message: 'Unable to add risk',
							isVisible: true,
						}),
					);
				}
				setShowModal(false);
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'Error',
						message: err.message,
						isVisible: true,
					}),
				);
				setShowModal(false);
			});
	};

	const updateRisk = (payload: IRiskPayload) => {
		if (risk) {
			payload.uuid = risk.uuid;

			apiStage
				.updateRisk(applicant.workerUuid, payload)
				.then((res) => {
					if (res.status !== 404) {
						dispatch(setStageRisks(res.data));
						dispatch(
							setAlert({
								type: 'success',
								message: 'Successfully updated risk',
								isVisible: true,
							}),
						);
					} else {
						dispatch(
							setAlert({
								type: 'Error',
								message: 'Unable to update risk',
								isVisible: true,
							}),
						);
					}
					setShowModal(false);
				})
				.catch((err) => {
					dispatch(
						setAlert({
							type: 'Error',
							message: err.message,
							isVisible: true,
						}),
					);
					setShowModal(false);
				});
		}
	};

	return (
		<div className='risk-modal'>
			<p className='stage-title'>{stage.title}</p>

			<div className='risk-check-container'>
				{riskTypeData.map((risk) => {
					return (
						<div key={risk.riskType} className='risk-check'>
							<img
								src={
									risk.selected ? checkboxBlue : checkboxWhite
								}
								alt='checkbox'
								onClick={() => handleSelectRisk(risk.dbVal)}
							/>
							<p>{risk.riskType}</p>
						</div>
					);
				})}
			</div>

			<div className='risk-description'>
				<textarea
					value={riskNotes}
					onChange={handleTextareaChange}
					placeholder='Please add some details about this risk'
				/>
			</div>

			<div className='risk-modal-button'>
				<div className='risk-modal-assigned-dropdown'>
					{defaultCompany && isTypeRisk && (
						<>
							<p className='orka-profile-label'>
								{selectedCompanyName
									? 'Company'
									: 'Select company'}
							</p>
							<Dropdown
								type='riskCompany'
								dropdownData={companies}
								defaultData={defaultCompany}
								getDropdownVal={getCompanyDropdownVal}
								width='180'
								height={24}
								mobileResponsive={false}
								position='top-center'
							/>
						</>
					)}
				</div>

				<div className='risk-modal-assigned-dropdown'>
					{defaultController && isTypeRisk && (
						<>
							<p className='orka-profile-label'>
								{selectedController
									? 'Assigned to'
									: 'Needs assigning'}
							</p>
							<Dropdown
								type='riskController'
								dropdownData={controllers}
								defaultData={defaultController}
								getDropdownVal={getDropdownVal}
								width='180'
								height={24}
								mobileResponsive={false}
								position='top-center'
							/>
						</>
					)}
				</div>
				<OrkaButton
					buttonContent='Done'
					disabled={isDisabled}
					emitClicked={handleSave}
				/>
			</div>
		</div>
	);
};

export default RiskModal;
